// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  ion-content{
    background-color: #eff0f3;
  }
  /** primary **/
  --ion-color-primary: #ebd272;
  --ion-color-primary-rgb: 235, 210, 114;
  --ion-color-primary-contrast: #001e1d;
  --ion-color-primary-contrast-rgb: 0, 30, 29;
  --ion-color-primary-shade: #EBD272;
  --ion-color-primary-tint: #EBD272;

  //粉紅底 button /** secondary **/ 
  --ion-color-secondary: #7B4D41;
  --ion-color-secondary-rgb: 123,77,65 ;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #7B4D41;
  --ion-color-secondary-tint: #7B4D41;

  //白底 標題\ion-badge /** tertiary **/ 
  --ion-color-tertiary: #d8b838;
  --ion-color-tertiary-rgb: 216, 184, 56;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #d8b838;
  --ion-color-tertiary-tint: #d8b838;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #F69E1C;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #F69E1C;
  --ion-color-warning-tint: #F69E1C;

  /** danger **/
  --ion-color-danger: #bc2d45;
  --ion-color-danger-rgb: 188, 45, 69;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #BC2D45;
  --ion-color-danger-tint: #BC2D45;

  /** dark **/
  --ion-color-dark: #2a2a2a;
  --ion-color-dark-rgb: 42, 42, 42;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #2a2a2a;
  --ion-color-dark-tint: #2a2a2a;

  /** medium **/
  --ion-color-medium: #6E6F73;
  --ion-color-medium-rgb: 110, 111, 115;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #6E6F73;
  --ion-color-medium-tint: #6E6F73;

  /** light **/
  --ion-color-light: #eff0f3;
  --ion-color-light-rgb: 239, 240, 243;
  --ion-color-light-contrast: #2a2a2a;
  --ion-color-light-contrast-rgb: 42, 42, 42;
  --ion-color-light-shade: #eff0f3;
  --ion-color-light-tint: #eff0f3;
}

